/* eslint-disable prettier/prettier */
import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// material-ui components
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";

class SectionCarousel extends React.Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };
    const images = [
      {id: 0, imgSrc: require("assets/img/slider/slider1.jpg")},
      {id: 1, imgSrc: require("assets/img/slider/slider2.jpg")},
      {id: 2, imgSrc: require("assets/img/slider/slider3.jpg")},
      {id: 3, imgSrc: require("assets/img/slider/slider4.jpg")},
    ];
    const slider = images.map((image) => (
        <div key={image.id}>
            <img src={image.imgSrc} alt="slide" className="slick-image" />
        </div>
    ));
    console.log("sli", slider, images);
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={1}></GridItem>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <Carousel {...settings}>
              {/* devi copiare i div */}
              {slider}
            </Carousel>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={1}></GridItem>
      </GridContainer>
    );
  }
}

export default SectionCarousel;
