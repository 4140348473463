/* eslint-disable prettier/prettier */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Parallax from "components/Parallax/Parallax.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

//facebook library
import { FacebookProvider, Page } from 'react-facebook-sdk';

// @material-ui/icons

import activityPageStyle from "assets/jss/material-kit-react/views/activityPage.jsx";

const dashboardRoutes = [];

class ActivityPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;

    return (
        <div>
            <Header
            color="transparent"
            routes={dashboardRoutes}
            // brand="Material Kit React"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
            height: 400,
            color: "amber"
            }}
            {...rest}
        />
        <Parallax filter small image={require("assets/img/carousel1.png")}>
            <div className={classes.container}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>

                </GridItem>
            </GridContainer>
            </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
          <GridContainer>
                <GridItem xs={12} sm={12} md={3}></GridItem>
                <GridItem xs={12} sm={12} md={7} style={{marginBottom: "5%",marginTop: "2%"}}>
                    <FacebookProvider appId="385881222047784">
                        <Page width="500" height="1000" href="https://www.facebook.com/shingeniltocco/" tabs="timeline" />
                    </FacebookProvider>    
                </GridItem>
                <GridItem xs={12} sm={12} md={1}></GridItem>
            </GridContainer>
                
          </div>
        </div>
        <Footer />
      </div>
       
    );
  }
}

export default withStyles(activityPageStyle)(ActivityPage);
