/* eslint-disable prettier/prettier */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Parallax from "components/Parallax/Parallax.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Typography from '@material-ui/core/Typography';
import NavPills from "components/NavPills/NavPills.jsx";




// @material-ui/icons

import associationPageStyle from "assets/jss/material-kit-react/views/associationPage.jsx";

const dashboardRoutes = [];

class AssociationPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;

    return (
        <div>
            <Header
            color="transparent"
            routes={dashboardRoutes}
            // brand="Material Kit React"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
            height: 400,
            color: "amber"
            }}
            {...rest}
        />
        <Parallax filter small image={require("assets/img/carousel1.png")}>
            <div className={classes.container}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>

                </GridItem>
            </GridContainer>
            </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
          <GridContainer>
                <GridItem xs={12} sm={12} md={1}></GridItem>
                <GridItem xs={12} sm={12} md={10} style={{marginTop: "2%"}}>
                <span className={classes.defaultText}>

                <p><b>L’Associazione Shiatsu “SHINGEN – IL TOCCO”</b> è un’associazione no-profit, creata con l’intento di diffondere la conoscenza dello shiatsu, attraverso trattamenti e corsi.</p>

                    <p>Nasce nel 2010, per iniziativa di quattro amici praticanti, uniti dall’interesse per tale arte e dai ripetuti viaggi per svolgere lezioni e seminari, desiderosi di dare una forma strutturata a iniziative che sentivano di dover promuovere, grazie alla conoscenza acquisita.<br />
                    Tra queste, in primis, rappresentare a Brescia la Scuola (il Centro Shiatsu Daniele Giorcelli di Leinì – Torino) presso cui si era svolta la propria formazione scolastica.</p>
                    <br></br>
                    <p>Nel tempo, si è sentita la necessità di estendere tali iniziative a attività inerenti il benessere e la salute psico-fisica, includendo la riflessologia plantare, la comunicazione non violenta, il massaggio olistico.</p>
                    <br></br>
                    <p>Ogni venerdì pomeriggio, a partire dalle ore 17.00, viene offerta al pubblico l’opportunità di ricevere trattamenti da studenti operatori e professionisti della Scuola, nonché riconosciuti dalla Federazione Italiana Shiatsu (FISieo), per far conoscere sia l’Associazione che il Centro Shiatsu Daniele Giorcelli.</p>
                    <br></br>
                    <p>Nel corso di ogni anno, la nostra organizzazione partecipa ad attività benefiche di raccolta fondi, collaborando con Enti e Associazioni no-profit.</p>
                </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={1}></GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={1}></GridItem>
                <GridItem xs={12} sm={12} md={10} style={{marginBottom: "5%",marginTop: "2%"}}>
                    <NavPills
                    color="personalized"
                    tabs={[
                        {
                        tabButton: "Orari e giorni",
                        tabContent: (
                            <span className={classes.defaultText}>
                            <p>
                            L’Associazione “Shingen – Il Tocco” è aperta tutti i giorni, in Via Rodi 7, a Brescia, in orari pomeridiani.
                            </p>
                            <p>
                            Per maggiori informazioni, contattare il n. 331 1276357, la pagina <a target="_blank" rel="noopener" href="https://www.facebook.com/shingeniltocco/">Facebook</a>, <a target="_blank" rel="noopener" href="https://twitter.com/shingeniltocco">Twitter</a>.
                            </p>
                            </span>
                        )
                        },
                        {
                        tabButton: "Responsabile per la sede di Brescia",
                        tabContent: (
                            <span className={classes.defaultText}>
                            <p>
                            è Guido Viacava, operatore professionista FISieo e insegnante del Centro Shiatsu Daniele Giorcelli, coadiuvato da assistenti insegnanti in formazione e studenti della scuola.
                            </p>
                            </span>
                        )
                        }
                    ]}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={1}></GridItem>
            </GridContainer>
                
          </div>
        </div>
        <Footer />
      </div>
       
    );
  }
}

export default withStyles(associationPageStyle)(AssociationPage);
