import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.4.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.jsx";
import SchoolPage from "views/SchoolPage/SchoolPage.jsx";
import ActivityPage from "views/ActivityPage/ActivityPage.jsx";
import AssociationPage from "views/AssociationPage/AssociationPage.jsx";
// import ContactForm from "views/LandingPage/Sections/WorkSection.jsx";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
    <Route path="/associazione" component={AssociationPage} />
    <Route path="/scuola" component={SchoolPage} />
    <Route path="/attivita" component={ActivityPage} />
    {/* <Route path="/contatti" component={ContactForm} /> */}
    <Route path="/" component={LandingPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
