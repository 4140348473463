/* eslint-disable react/jsx-key */
/* eslint-disable prettier/prettier */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import gapi from 'gapi-client';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import moment from "moment";
import Divider from '@material-ui/core/Divider';
import localization from 'moment/locale/it'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';


import calendarStyle from "assets/jss/material-kit-react/components/calendarStyle.jsx";
import Button from '@material-ui/core/Button';







class Calendar extends React.Component{
    constructor(props) {
      super(props)
      this.state = {
        events: []
      }
    }

    componentDidMount = () => {
        this.getEvents();
    }

    getEvents(){
    let that = this;
    var today = new Date().toISOString();
    console.log(today);
    let pt = 'https://www.googleapis.com/calendar/v3/calendars/shingeniltocco@gmail.com/events?singleEvents=true&timeMin=' + today + '&maxResults=7&orderBy=startTime&timeZone=UTC+2';
    console.log(pt);
    function start() {
        gapi.client.init({
        'apiKey': "AIzaSyBMtX8MThyXUguykAwo5ldlj0yBtSyH67U"
        }).then(function() {
        return gapi.client.request({
            'path': pt
        })
        }).then( (response) => {
        let events = response.result.items
        that.setState({
            events
        }, ()=>{
            console.log(that.state.events);
        })
        }, function(reason) {
        console.log(reason);
        });
    }
    gapi.load('client', start)
    }

    render(){
        const { classes } = this.props;
        const { events } = this.state;

        const renderedEvents = events.map((event) => (
            <ExpansionPanel key={event.id}>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                    >
                    <Typography className={classes.heading}>{event.summary}</Typography>
                    <Typography className={classes.secondaryHeading}>
                            {moment(event.start.dateTime === undefined ? event.start.date : event.start.dateTime).format("D MMMM")}{event.start.dateTime != undefined ? ", " : " "} {event.start.dateTime != undefined ? moment(event.start.dateTime).format("HH:mm") : ""} {event.start.dateTime != undefined ? "-" : ""} {event.start.dateTime != undefined ? moment(event.end.dateTime).format("HH:mm") : ""} </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography style={{textAlign:"left"}} dangerouslySetInnerHTML={{__html:event.description ? event.description.replace(/\n/ig, '</br>') : ""}}>
                            
                        </Typography>
                        </ExpansionPanelDetails>
                        <ExpansionPanelActions>
                            <Button href={event.htmlLink} target="_blank" size="small" color="primary">Visualizza in google calendar </Button>
                        </ExpansionPanelActions>
            </ExpansionPanel>
            
        ));
        return <React.Fragment>{renderedEvents}</React.Fragment>;
  }
}


export default withStyles(calendarStyle)(Calendar);