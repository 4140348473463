/* eslint-disable prettier/prettier */

const calendarStyle = {
heading: {
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    color: "#7c7c7c",
  },
  myIcon: {
    color: "#ffca28",
    verticalAlign: 'bottom',
    fontSize: 35
  
  }
}


export default calendarStyle;
