/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import School from "@material-ui/icons/School";
import LocalLibrary from "@material-ui/icons/LocalLibrary";
import EventAvailable from "@material-ui/icons/EventAvailable";
import Calendar from "@material-ui/icons/EventNote";




// @material-ui/icons
import { Apps, PermContactCalendar } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
      {/* <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Components"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/" className={classes.dropdownLink}>
              All components
            </Link>,
            <a
              href="https://creativetimofficial.github.io/material-kit-react/#/documentation"
              target="_blank"
              className={classes.dropdownLink}
            >
              Documentation
            </a>
          ]}
        />
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <Link to="/associazione" className={classes.link}>
          <Button
            color="transparent"
            className={classes.navLink}
          >
            <LocalLibrary className={classes.icons} /> Associazione
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/scuola" className={classes.link}>
          <Button
            color="transparent"
            className={classes.navLink}
          >
            <School className={classes.icons} /> Scuola
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/attivita" className={classes.link}>
          <Button
            color="transparent"
            className={classes.navLink}
          >
            <EventAvailable className={classes.icons} /> Attività
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
      {/* <Link to={"/contatti"} className={classes.link}> */}
        <Button
          href="https://www.shingeniltocco.it/#contatti"
          color="transparent"
          className={classes.navLink}
        >
          <PermContactCalendar className={classes.icons} /> Contatti
        </Button>
        {/* </Link> */}
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="https://calendar.google.com/calendar/embed?src=shingeniltocco%40gmail.com&ctz=Europe%2FRome"
          target="_blank"
          color="transparent"
          className={classes.navLink}
        >
          <Calendar className={classes.icons} /> Calendario
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-twitter"
          title="Seguici su Twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://twitter.com/shingeniltocco"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-twitter"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Seguici su Facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/shingeniltocco/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/CreativeTimOfficial"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem> */}
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
