/* eslint-disable prettier/prettier */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Parallax from "components/Parallax/Parallax.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";


// @material-ui/icons

import schoolPageStyle from "assets/jss/material-kit-react/views/schoolPage.jsx";

const dashboardRoutes = [];

class SchoolPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;

    return (
        <div>
            <Header
            color="transparent"
            routes={dashboardRoutes}
            // brand="Material Kit React"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
            height: 400,
            color: "amber"
            }}
            {...rest}
        />
        <Parallax filter small image={require("assets/img/carousel1.png")}>
            <div className={classes.container}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>

                </GridItem>
            </GridContainer>
            </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
          <GridContainer>
                <GridItem xs={12} sm={12} md={1}></GridItem>
                <GridItem xs={12} sm={12} md={10} style={{marginBottom: "5%",marginTop: "2%"}}>
                <NavPills
                color="personalized"
                tabs={[
                    {
                    tabButton: "I Corsi di Formazione",
                    tabContent: (
                        <span className={classes.defaultText}>
                        <p>
                        Presso la sede dell'Associazione Shiatsu "Shingen - Il Tocco", in via Rodi 7, a Brescia, si svolgono i corsi di formazione professionale triennale del <a target="_blank" rel="noopener" href="http://www.nonsoloshiatsu.it/">Centro Shiastu Daniele Giorcelli di Leinì (TO)</a>.<br /> Il Centro Shiatsu Daniele Giorcelli è riconosciuto dalla Federazione Nazionale Scuole e Shiatsu (FNSS)e il percorso proposto risponde agli standard formativi richiesto dalla Federazione Italiana Shiatsu Insegnanti e Operatori (FISieo).
                        </p>
                        <br />
                        <p>
                        La preparazione risulta di assoluta qualità e mette in grado di accedere all'esame per l'iscrizione al Registro Italiano Operatori Shiatsu (RIOS) della FISieo.<br /> La L. 4/2013 riconosce la figura dell'operatore shiatsu professionista, iscritto alla FISieo.<br /> Ciò detto, possono comunque iscriversi ai corsi anche coloro che fossero interessati a praticare trattamenti semplicemente a livello amatoriale, avendo la certezza di possedere il bagaglio tecnico necessario per affrontare ogni situazione particolare.
                        </p>
                        <br />
                        <p>
                        I corsi sono strutturati in 5 livelli, per una crescita progressiva di apprendimento, compatibile alle finalità del programma di formazione, ma anche al diverso grado d'impegno che ciascuno può dedicare allo studio.<br /> E' reso possibile partecipare ai singoli livelli, senza necessariamente impegnarsi a concludere l'intero programma e le eventuali assenze sono recuperabili, anche a distanza di tempo, senza aggravio di costi. <br />Il percorso standard si sviluppa in circa 800 ore didattiche, sia teoriche che sopratutto pratiche, al termine delle quali si suppone sia possibile sostenere l'esame per diventare "Operatore Shistsu Professionista", iscritto all'albo della FISieo e del RIOS.<br /> Al termine di questo programma, agli studenti è offerta la possibilità di accedere al Master di "Gengo Shiatsu - Il Linguaggio della Pressione®", metodo esclusivo di pratica del Centro Shiatsu Daniele Giorcelli.
                        </p>
                        </span>
                    )
                    },
                    {
                    tabButton: "Giorcelli e il Gengo Shiatsu",
                    tabContent: (
                        <span className={classes.defaultText}>
                        <p>
                        ll fondatore della <a target="_blank" rel="noopener" href="http://www.nonsoloshiatsu.it/">Scuola</a>, Daniele Giorcelli, nel corso della sua ventennale esperienza di Operatore Professionista, ha elaborato un proprio modello di pratica del tutto nuovo, che non si ricollega ad alcun altro contesto.<br /> Nel Gengo Shiatsu (gen = originale, primitivo; go = linguaggio), si cerca di andare alla radice del gesto, individuando una modalità esclusiva e singolare, un linguaggio originale, espressamente pensati per lo shiatsu e non presi a prestito dalla MTC o dalla psicologia o fisiologia: il linguaggio della pressione.
                        </p>
                        <br />
                        <p>
                        Uno shiatsu senza riferimenti, se non la risposta complessiva del corpo-mente, non focalizzandosi cioè su un solo aspetto che caratterizza l’essere umano (fisico, emotivo, psichico, energetico etc.), ma esaminandoli tutti nel loro insieme.<br /> Giorcelli ha coniato la metafora del cubo di Rubik, le cui diverse facce sono a loro volta composte da altre facce più piccole, tutte colorate.<br /> Quando siamo in equilibrio, tutte le facce colorate (i nostri corpi – dal fisico al mentale) sono al posto giusto.
                        </p>
                        </span>
                    )
                    }
                ]}
                />
                </GridItem>
                <GridItem xs={12} sm={12} md={1}></GridItem>
            </GridContainer>
                
          </div>
        </div>
        <Footer />
      </div>
       
    );
  }
}

export default withStyles(schoolPageStyle)(SchoolPage);
