/* eslint-disable prettier/prettier */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Phone from "@material-ui/icons/ContactPhone";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Link from '@material-ui/core/Link';



import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

class WorkSection extends React.Component {

  constructor(){
    super();

    this.state = {

    }
  }

  encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  handleChange(e) {
    console.log("state", this.state);
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit(e) {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: this.encode({ 'form-name': 'contact', ...this.state }),
    })
      .then(() => window.location.href = '/')
      .catch(error => alert(error))

    e.preventDefault()
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer id="contatti" justify="center">
          <GridItem cs={12} sm={12} md={6}>
          <h2 className={classes.title}>Chiamaci</h2>
          <Link href="tel:+393311276357"><h3 className={classes.title} style={{marginBottom: "0px"}}><Phone style={{fontSize:"30px", marginBottom:"-5px"}}></Phone> Guido: 331-1276357</h3></Link>
            <h2 className={classes.title}>Scrivici</h2>
            <h4 className={classes.description}>
            Risponderemo il prima possibile
            </h4>
            <form name="contact"
              id="contact"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={(e) => this.handleSubmit(e)}>
              <input type="hidden" name="bot-field" value="contact" />
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Il tuo nome"
                    id="name"
                    name="nome"
                    formControlProps={{
                      fullWidth: true
                    }}
                    onChange={(e) => this.handleChange(e)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="La tua Email"
                    id="email"
                    name="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    onChange={(e) => this.handleChange(e)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Oggetto"
                    id="subject"
                    name="oggetto"
                    formControlProps={{
                      fullWidth: true
                    }}
                    onChange={(e) => this.handleChange(e)}
                  />
                </GridItem>
                <CustomInput
                  labelText="Il tuo messaggio"
                  id="message"
                  name="messaggio"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.textArea
                  }}
                  inputProps={{
                    multiline: true,
                    rows: 5
                  }}
                  onChange={(e) => this.handleChange(e)}
                />
                <GridContainer justify="center">
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.textCenter}
                  >
                    <Button name="submit" type="submit" color="primary">Invia messaggio</Button>
                  </GridItem>
                </GridContainer>
              </GridContainer>
            </form>
            {/* <form
              name="contact"
              id="contact-form"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="bot-field" value="contact" />
              <h3>Scrivici</h3>
              <h4>Risponderemo il prima possibile</h4>
              <div>
                <label>
                  <input
                    placeholder="Nome"
                    id="name"
                    type="text"
                    autoFocus
                    name="nome"
                    onChange={this.handleChange}
                  />
                </label>
              </div>
              <div>
                <label>
                  <input
                    placeholder="Email"
                    id="email"
                    type="email"
                    name="email"
                    onChange={this.handleChange}
                    required
                  />
                </label>
              </div>
              <div>
                <label>
                  <input
                    placeholder="Oggetto"
                    id="subject"
                    type="text"
                    name="oggetto"
                    onChange={this.handleChange}
                  />
                </label>
              </div>
              <div>
                <label>
                  <textarea
                    placeholder="Inserisci il tuo messaggio"
                    id="message"
                    required
                    name="messaggio"
                    onChange={this.handleChange}
                  />
                </label>
              </div>
              <div>
                <button name="submit" type="submit" id="contact-submit">
                  Invia
                </button>
              </div>
              <p id="returnmessage" />
            </form> */}
          </GridItem>
          <GridItem cs={12} sm={12} md={6}>
            <div className="map">
              <iframe
                style={{ border: 0 }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2795.2444582074513!2d10.208926000000003!3d45.525286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478176765701c563%3A0x899908341930bb82!2sShingen+-+Il+Tocco!5e0!3m2!1sit!2sit!4v1423306167455"
                width="100%"
                height="650"
                frameBorder="0"
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(workStyle)(WorkSection);
