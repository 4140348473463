/* eslint-disable prettier/prettier */
import React from "react";

import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import School from "@material-ui/icons/School";
import LocalLibrary from "@material-ui/icons/LocalLibrary";
import EventAvailable from "@material-ui/icons/EventAvailable";
import Calendar from "@material-ui/icons/EventNote";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";
import GoogleCalendar from "./GoogleCalendar.jsx";
// import Link from "@material-ui/core/Link";


class ProductSection extends React.Component {
  render() {
    const { classes } = this.props;
    const associazioneDescription = <React.Fragment>L’Associazione Shiatsu “Shingen – Il Tocco” dal 2011 promuove corsi di shiatsu certificati <a style={{ color: "#d10d00"}} href="http://www.fisieo.it/">FISieo</a>, con cui conseguire il diploma di operatore professionista, riconosciuto dalla L. 4/2013.</React.Fragment>;
    const schoolIcon = "";
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
             {/* <h2 className={classes.title}>Scopri lo shiatsu</h2> 
             <img src={require("assets/img/logo_scritta.svg")} /> 
             <Card className={classes.card}>
                <CardMedia
                  className={classes.media}
                  image="/assets/img/logo_scritta.svg"
                  title="logo scritta"
                />
            </Card> */}
            <h4 className={classes.description}>
            L’Associazione “Shingen - Il Tocco” intende promuovere diverse iniziative finalizzate al benessere e alla salute psico-fisica.<br />
                  Tra queste, lo Shiatsu è l’attività principale, per la quale sono organizzati corsi e trattamenti. <br />L’Associazione si avvale di operatori professionisti e insegnanti certificati.
            </h4> 
          </GridItem>
        </GridContainer>
        <a hover="none" className={classes.calendar} target="_blank" rel="noreferrer" href="https://calendar.google.com/calendar/embed?src=shingeniltocco%40gmail.com&ctz=Europe%2FRome">Calendario   <Calendar style={{ marginBottom:"-8", marginTop:"15px" ,fontSize:"50px"}}></Calendar></a>
        <GoogleCalendar />
        <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
             <h2 className={classes.calendar}>Per saperne di più clicca su:</h2> 
             {/* <img src={require("assets/img/logo_scritta.svg")} />  */}
          </GridItem>
        </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Link to={"/associazione"}>
                <InfoArea
                  title="Associazione"
                  description={associazioneDescription}
                  icon={LocalLibrary}
                  iconColor="info"
                  vertical
                />
              </Link>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Link to={"/scuola"}>
                <InfoArea
                  title="Scuola"
                  description="I corsi sono strutturati in cinque livelli, attraverso i quali agli studenti è resa possibile una crescita progressiva di apprendimento, compatibilmente alle finalità del programma di formazione, ma anche al diverso grado di impegno che ciascuno può dedicare allo studio. Durante l’anno ci sarà l’opportunitrà di accedere a vari seminari di approfondimento"
                  icon={School}
                  iconColor="success"
                  vertical
                />
              </Link>
            </GridItem>
            {/* aggiugere modali per visione completa della feature */}
            <GridItem xs={12} sm={12} md={4}>
              <Link to={"/attivita"}>
                <InfoArea
                  title="Attività"
                  description="Le attività del centro sono rivolte a tutti coloro che vogliono avvicinarsi a queste pratiche.  
                  L’associazione Shiatsu “Shingen – Il tocco” durante l’anno propone vari tipi di eventi, clicca per saperne di più!"
                  icon={EventAvailable}
                  iconColor="danger"
                  vertical
                />
              </Link>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(ProductSection);
